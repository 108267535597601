import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { FlexiSummary } from '../flexisummary';
import { DentalSummary } from '../dentalsummary';
import { EAPSummary } from '../eapsummary';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { UserService } from '../loginCore/user.service';
import { Claim } from '../claim';

@Component({
  selector: 'app-hr-claimdetail',
  templateUrl: './hr-claimdetail.component.html',
  styleUrls: ['./hr-claimdetail.component.css']
})
export class HrClaimdetailComponent implements OnInit {


  claimDetail: Claim;

  claimId: string;
  notes: string;
  employeeNotes: string;
  personId: string;
  employeeId: string;
  claimType: string;
  chkConsent: string;

  flexiSummary: FlexiSummary;
  dentalSummary: DentalSummary;
  eapSummary: EAPSummary;

  claimAmount: number;
  availableAmount: number;

  quotaYear: string;
  currentYear: string;
  lastYear: string;

  flexiType: string = "Flexible";
  dentalType: string = "Dental";
  eapType: string = "EAP (Mental Health)";
  showFlexi: boolean = false;
  showDental: boolean = false;
  showEAP: boolean = false;

  disableLoading: number = 0;

  claim: object = [];
  status = [
    { id: 1, label: 'Submitted' },
    { id: 2, label: 'Rejected' },
    { id: 3, label: 'Cancelled' },
    { id: 4, label: 'Approved' },
    { id: 5, label: 'Pending Payment' }
  ];
  selectedStatus: string;
  systemMessage: string;

  constructor(
    public claimService: ClaimService,
    public userService: UserService,
    private loadingService: LoadingService,
    private router: Router,
    public snackBar: MatSnackBar,
    public utilService: UtilService
  ) { }

  ngOnInit() {
    this.loadingService.enableLoading();
    if (this.claimService.getClaimInformation() === undefined) {
      this.claimDetail = JSON.parse(localStorage.getItem('claimObj'));
      this.claimAmount = this.convertStringtoNumber(this.claimDetail.claimAmount);
      this.getAvailableBalance(this.claimDetail.typeOfClaim, this.claimDetail.personId, this.claimDetail.employeeId);
      this.notes = this.claimDetail.notes;
      this.employeeNotes = this.claimDetail.employeeNotes;
      this.chkConsent = this.claimDetail.chkConsent;
    } else {
      this.claimDetail = this.claimService.getClaimInformation();
      this.claimAmount = this.convertStringtoNumber(this.claimService.getClaimInformation().claimAmount);
      localStorage.setItem('claimObj', JSON.stringify(this.claimService.getClaimInformation()));
      this.getAvailableBalance(this.claimService.getClaimInformation().typeOfClaim,
        this.claimService.getClaimInformation().personId, this.claimService.getClaimInformation().employeeId);
      this.notes = this.claimDetail.notes;
      this.employeeNotes = this.claimDetail.employeeNotes;
      this.chkConsent = this.claimDetail.chkConsent;   
    }
    this.selectedStatus = this.claimDetail.status;
    this.userService.setHRMenu("hr");
  }

  convertStringtoNumber(amount: string): number {
    return Number(amount);
  }

  selectedStatusHandler(event: any) {
    // expect to return selected id
    this.selectedStatus = this.status[event - 1].label;
  }

  updateClaim(): void {
    this.claim = {
      claimId: this.claimDetail.claimId, status: this.selectedStatus, notes: this.notes
    }
    if (this.selectedStatus == "Approved") {
      // This checking is for Insurance Type, however Insurance type was removed
      if (this.claim["typeOfClaim"] == this.dentalType) {
        // valid the dental claim is lower or equal to the available amount
        if ((this.claimAmount > this.availableAmount) && (this.availableAmount <= 0)) {
          this.openAlertSnackbar("The claim amount has exceeded the available amount.");
          return;
        }
      } else if (this.claim["typeOfClaim"] == this.flexiType) {
        // valid the flexi claim is lower or equal to the available amount
        if (this.claimAmount > this.availableAmount && (this.availableAmount <= 0)) {
          this.openAlertSnackbar("The claim amount has exceeded the available amount.");
          return;
        }
      } else if (this.claim["typeOfClaim"] == this.eapType) {
        // valid the eap claim is lower or equal to the available amount
        if (this.claimAmount > this.availableAmount && (this.availableAmount <= 0)) {
          this.openAlertSnackbar("The claim amount has exceeded the available amount.");
          return;
        }
      }
    }

    this.claimService.updateClaim(this.claim).subscribe(
      (result) => {
        this.openSnackbar('Claim has been successfully updated.');
        this.router.navigate(['/hrViewClaim']);
      },
      (err) => {
        this.openAlertSnackbar('There is an error: ' + err.message);
      }
    );
  }

  getAvailableBalance(claimType: string, personID: string, employeeID: string) {
    this.quotaYear = this.claimService.formatDate(this.claimDetail.receiptDate).substring(0, 4);
    if (claimType == this.flexiType) {
      this.getFlexiPointHR(employeeID);
      this.showFlexi = true;
      this.showDental = false;
      this.showEAP = false;
    } else if (claimType == this.dentalType) {
      this.getDentalSummaryHR(personID, employeeID)
      this.showDental = true;
      this.showFlexi = false;
      this.showEAP = false;
    } else if (claimType == this.eapType) {
      this.getEAPBalanceHR(employeeID)
      this.showDental = false;
      this.showFlexi = false;
      this.showEAP = true;
    }
  }

  getFlexiPointHR(employeeID: string) {
    this.claimService.getFlexiPointHR(employeeID, this.quotaYear)
      .subscribe(flexiSummary => {
        this.flexiSummary = flexiSummary;
        this.availableAmount = Number(this.flexiSummary["availableFlexi"]);
        this.disableLoading += 1;
        if (this.disableLoading == 1) this.loadingService.disableLoading();
      });
  }

  getDentalSummaryHR(personID: string, employeeID: string) {
    this.claimService.getDentalSummaryHR(personID, employeeID, this.quotaYear)
      .subscribe(dentalSummary => {
        this.dentalSummary = dentalSummary;
        this.availableAmount = Number(this.dentalSummary["availableDental"]);
        this.disableLoading += 1;
        if (this.disableLoading == 1) this.loadingService.disableLoading();
      });
  }

  getEAPBalanceHR(employeeID: string) {
    this.claimService.getEAPBalanceHR(employeeID, this.quotaYear)
      .subscribe(eapSummary => {
        this.eapSummary = eapSummary;
        this.availableAmount = Number(this.eapSummary["availableEAP"]);
        this.disableLoading += 1;
        if (this.disableLoading == 1) this.loadingService.disableLoading();
      });
  }

  cancel(): void {
    this.utilService.keepValue();
    this.router.navigate(['/hrViewClaim']);
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, "Got it!",
      {
        duration: 3000
      });
  }

  openAlertSnackbar(msg: string) {
    this.snackBar.open(msg, "Dismiss",
      {
        duration: 10000,
        panelClass: ["snack-error"]
      });
  }

  getQuotaYears() {
    var quotaYears = this.utilService.getQuotaYears();
    this.currentYear = "" + quotaYears[0];
    this.lastYear = "" + quotaYears[1];
  }

  isQuotaYearCurrentYear() {
    this.getQuotaYears();
    //console.log("this.quotaYear: "+this.quotaYear +" | this.currentYear: "+this.currentYear);
    return (this.quotaYear == this.currentYear);
  }

}

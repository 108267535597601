<app-navigation></app-navigation>
<div class="container-fluid">
  <div #reportpdf class="table-responsive text-nowrap">
    <h2 class="mt-3 solvay">HR View Claim</h2>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="solvay">Claim ID:</label>
        <input type="text" class="form-control" [(ngModel)]="claimId" name="claimId" />
      </div>
      <div class="form-group col-md-6">
        <label class="solvay">Status:</label>
        <select class="form-control solvay" [(ngModel)]="status['id']" (change)="selectedStatusHandler(status['id'])">
          <option *ngIf="this.selectedStatus === undefined" [ngValue]="undefined" hidden>Select Status</option>
          <option *ngIf="this.selectedStatus !== undefined" [ngValue]="undefined" hidden>{{ this.selectedStatus }}</option>
          <option *ngFor="let i of status" [ngValue]="i.id">{{ i.label }}</option>
        </select>
        <span *ngIf="selectedStatus" class="clear-icon" (click)="clearStatus()">
          &#x2715; <!-- X mark character -->
        </span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="solvay">Employee ID:</label>
        <input type="text" class="form-control" [(ngModel)]="employeeId" name="employeeId" />
      </div>
      <div class="form-group col-md-6">
        <label class="solvay">Receipt Number:</label>
        <input type="text" class="form-control" [(ngModel)]="receiptNo" name="receiptNo" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <label class="solvay">Submission Date From:</label>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control solvay" readonly="true" placeholder="yyyy-mm-dd" [(ngModel)]="submissionDateFrom"
              name="submissionDateFrom" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <img src="assets/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="solvay">Submission Date To:</label>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control solvay" readonly="true" placeholder="yyyy-mm-dd" [(ngModel)]="submissionDateTo"
              name="submissionDateTo" ngbDatepicker #e="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="e.toggle()" type="button">
                <img src="assets/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      &nbsp;&nbsp;
      <button type="submit" class="btn btn-success mr-2" (click)="search()">Search</button>
      <button type="reset" class="btn btn-danger" (click)="reload()">Reset</button>
    </div>
    <br /><br />
    <table class="table" matSort (matSortChange)="sortData($event)">
      <thead>
        <tr>
          <th mat-sort-header="claimId" scope="col">Claim ID</th>
          <th mat-sort-header="employeeId" scope="col">Employee ID</th>
          <th mat-sort-header="submissionDate" scope="col">Submission Date</th>
          <th mat-sort-header="typeOfClaim" scope="col">Claim Type</th>
          <th mat-sort-header="categoryOfClaim" scope="col">Claim Category</th>
          <th mat-sort-header="dependentName" scope="col">Claim For</th>
          <th mat-sort-header="claimAmount" scope="col">Total Claim</th>
          <th mat-sort-header="receiptNo" scope="col">Receipt Number</th>
          <th mat-sort-header="status" scope="col">Claim Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let claim of sortedData" class="clickable" (click)="detail(claim.claimId)">
          <td>{{ this.claimService.formatClaimID(claim.claimId) }}</td>
          <td>{{ claim.employeeId }}</td>
          <td>{{ this.claimService.formatDate(claim.submissionDate) }}</td>
          <td>{{ claim.typeOfClaim }}</td>
          <td>{{ claim.categoryOfClaim }}</td>
          <td>{{ claim.dependentName }}</td>
          <td>{{ this.claimService.formatMoney(claim.claimAmount) }}</td>
          <td>{{ claim.receiptNo }}</td>
          <td>{{ claim.status }}</td>
        </tr>
      </tbody>
    </table>
    
    
  </div>
</div>
<app-footer></app-footer>
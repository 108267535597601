import { Component, OnInit, ViewChild } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Claim } from '../claim';
import { Router } from '@angular/router';
import { ClaimSearchPipe } from '../claimsearch.pipe';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { Sort } from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';



@Component({
  selector: 'app-hr-viewclaim',
  templateUrl: './hr-viewclaim.component.html',
  styleUrls: ['./hr-viewclaim.component.css']
})
export class HrViewclaimComponent implements OnInit {

  claims: Claim[] = [];
  filteredClaims: Claim[] = [];
  pagedData: Claim[] = [];
  sortedData: Claim[] = [];

  claimId: string;
  employeeId: string;
  receiptNo: string;

  typeOfClaim = [
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" }
  ];

  status = [
    { id: 1, label: 'Pending Attachment' },
    { id: 2, label: 'Submitted' },
    { id: 3, label: 'Rejected' },
    { id: 4, label: 'Cancelled' },
    { id: 5, label: 'Approved' },
    { id: 6, label: 'Pending Payment' },
    { id: 7, label: 'Paid' }
  ];

  submissionDateFrom: NgbDateStruct;
  submissionDateTo: NgbDateStruct;

  selectedClaimType: string;
  selectedStatus: string;
  disableLoading: number = 0;

  sort: Sort = { active: 'claimId', direction: 'asc' };


  constructor(
    private claimService: ClaimService,
    private router: Router,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private loadingService: LoadingService,
    private utilService: UtilService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initializeDefaults();
    this.getClaims();
  }

  initializeDefaults(): void {
    const today = new Date();
    this.submissionDateFrom = this.getDateStruct(this.getSixMonthsAgo(today));
    this.submissionDateTo = this.getDateStruct(today);
    this.selectedStatus = 'Submitted';
    this.selectedClaimType = null;
  }

  selectedClaimTypeHandler(event: any): void {
    this.selectedClaimType = this.typeOfClaim[event - 1]?.label;
    this.search();
  }

  selectedStatusHandler(event: any): void {
    this.selectedStatus = this.status[event - 1]?.label;
    this.search();
  }

  getSixMonthsAgo(date: Date): Date {
    const sixMonthsAgo = new Date(date);
    sixMonthsAgo.setMonth(date.getMonth() - 6);
    return sixMonthsAgo;
  }

  getDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  convertToDate(ngbDate: NgbDateStruct): Date {
    return ngbDate ? new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day) : null;
  }

  getClaims(): void {
    this.loadingService.enableLoading();
    this.claimService.getClaims().subscribe(claims => {
      this.claims = claims;
      this.filteredClaims = claims;

  
      // Trigger search to apply default filters and sorting
      this.search();
      this.loadingService.disableLoading();
    });
  }
  

  search(): void {
    console.log("Search triggered with values:", {
      submissionDateFrom: this.submissionDateFrom,
      submissionDateTo: this.submissionDateTo,
      selectedStatus: this.selectedStatus,
    });
  
    let resultClaim = new ClaimSearchPipe();
  
    let dateFrom = this.submissionDateFrom ? this.convertToDate(this.submissionDateFrom) : null;
    let dateTo = this.submissionDateTo ? this.convertToDate(this.submissionDateTo) : null;
  
    // Apply filters
    this.filteredClaims = resultClaim.transform(
      this.claims,
      this.claimService.unformatClaimID(this.claimId),
      this.selectedClaimType,
      dateFrom,
      dateTo,
      this.selectedStatus,
      this.employeeId,
      this.receiptNo,
      null  // Optional: Add dependent name filter if necessary
    );
  
    // Sort the filtered claims
    this.sortData(this.sort);
  
  }
  clearStatus(): void {
    this.selectedStatus = '';  // Reset the selected status
    this.search();  // Trigger the search with the cleared status
  }
  
  

  sortData(sort: Sort): void {
    const { active, direction } = sort;
  
    if (!active || direction === '') {
      this.sortedData = [...this.filteredClaims]; // No sort, reset to filtered data
    } else {
      this.sortedData = [...this.filteredClaims].sort((a, b) => {
        const isAsc = direction === 'asc';
        switch (active) {
          case 'claimId': return this.compare(a.claimId, b.claimId, isAsc);
        case 'employeeId': return this.compare(a.employeeId, b.employeeId, isAsc);
        case 'typeOfClaim': return this.compare(a.typeOfClaim, b.typeOfClaim, isAsc);
        case 'dependentName': return this.compare(a.dependentName, b.dependentName, isAsc);
        case 'claimAmount': return this.compare(a.claimAmount, b.claimAmount, isAsc);
        case 'status': return this.compare(a.status, b.status, isAsc);
        case 'submissionDate': return this.compare(a.submissionDate, b.submissionDate, isAsc);
        case 'categoryOfClaim': return this.compare(a.categoryOfClaim, b.categoryOfClaim , isAsc);
        case 'receiptNo': return this.compare(a.receiptNo , b.receiptNo , isAsc);


          default: return 0;
        }
      });
    }

  }
  

  compare(a: any, b: any, isAsc: boolean): number {
    return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
  }

  detail(claimId: string) {
    // Find the claim in the list
    const claim = this.claims.find(c => c.claimId === claimId);
  
    if (claim) {
      // Set the claim information into localStorage
      localStorage.setItem('claimObj', JSON.stringify(claim)); // Store in localStorage
  
      // Open the claim detail page in a new tab
      window.open('/hrClaimDetail', '_blank');
    } else {
      console.log('Claim not found');
    }
  }
  
  
  
  reload(): void {
    location.reload();
  }
}